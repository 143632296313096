.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.imageswiper {
  height: 375px;
  width: 100%;
}

.imageswiper-thumbs, .videoswiper-thumbs {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
}

.imageswiper-thumbs .swiper-slide {
  width: 100%;
  height: 110px;
  opacity: 0.4;
}

.videoswiper video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Scale the video to fit the container without cropping */
  object-position: center; /* Ensure the video is centered */
  max-width: 100%; /* Prevent overflow in full screen */
  max-height: 100%; /* Prevent overflow in full screen */
}

.videoswiper-thumbs .swiper-slide {
  width: 100%;
  height: 110px;
  opacity: 0.4;
}

.imageswiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.videoswiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-button-prev, .swiper-button-next {
  color: #CBCFD4;
  background-color: #30363dbf;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.swiper-button-next {
  padding-left: 2px;
}

.swiper-button-prev {
  padding-right: 2px;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 12px;
  font-weight: 700;
}

.swiper-button-next .swiper-button-disabled {
  font-size: 15px;
  font-weight: 700;
}

.swiper-lazy-preloader {
  border-top-color: #f27a54 !important; /* Orange color */
  border-left-color: #f27a54 !important; /* Orange color */
  border-bottom-color: #f27a54 !important; /* Orange color */
  border-right-color: transparent;
}